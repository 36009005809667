// extracted by mini-css-extract-plugin
export var relativeWrapper = "news-module--relativeWrapper--3xxA3";
export var decor = "news-module--decor--210X_";
export var container = "news-module--container--361PC";
export var newsFeatureContainer = "news-module--newsFeatureContainer--A8Nys";
export var newsFeatureContent = "news-module--newsFeatureContent--2JQW2";
export var newsAuthorBlock = "news-module--newsAuthorBlock--1V8Ul";
export var newsFeatureMedia = "news-module--newsFeatureMedia--1C5SR";
export var newsPostContainer = "news-module--newsPostContainer--38jhx";
export var newsPostList = "news-module--newsPostList--3EdAG";
export var newsMore = "news-module--newsMore--4egff";