import React from "react";
import * as styles from "./news.module.scss";
import { useStaticQuery, graphql } from "gatsby";

import Layout from "@arbol/Layout";
import Footer from "@arbol/Footer";
import ContactForm from "@arbol/ContactForm";
import NewsPageBase from '@components/NewsPage';
import Seo from "@components/seo";

function IndexPage() {
  const data = useStaticQuery(graphql`
    query news {
      allPrismicNewsPost(
        filter: {data: {page_selection: {eq: "Arbol"}}}
        sort: {order: DESC, fields: last_publication_date}
      ) {
        nodes {
          tags
          id
          data {
            featured
            external_link {
              url
              target
            }
            title {
              text
            }
            intro_text {
              text
            }
            author {
              text
              html
            }
            author_image {
              url
            }
            main_image {
              url
            }
            small_image {
              url
            }
            page_selection
          }
        }
      }
    }
  `);

  const newsPosts = data.allPrismicNewsPost.nodes;

  return (
    <Layout transparentHeader={false}>
      <Seo title="News | Arbol by Gomes" description="News and events happening at Arbol, downtown Newark’s newest modern living concept" />
      <NewsPageBase newsPosts={newsPosts} styles={styles} />
      <ContactForm />
      <Footer />
    </Layout>
  );
}

export default IndexPage;
